import React from "react";
import { TransitionGroup, Transition as ReactTransition } from "react-transition-group";

const timeout = 350;
const transitionStyles = {
    entering: {
        position: `absolute`,
        transition: `${timeout}ms ease-in-out`,
        opacity: 0,
        transform: "translate3d(0,-2vh,0)"
    },
    entered: {
        transition: `${timeout}ms ease-in-out`,
        opacity: 1,
        transform: "translate3d(0,0,0)"
    },
    exiting: {
        transition: `${timeout}ms ease-in-out`,
        opacity: 0,
        transform: "translate3d(0,2vh,0)"
    },
}

export default ({ children, location }) => {
    return (
        <TransitionGroup>
            <ReactTransition
                key={location}
                timeout={{
                    enter: timeout,
                    exit: timeout,
                }}
            >
                {status => (
                    <div style={{ ...transitionStyles[status] }}>
                        {children}
                    </div>
                )}
            </ReactTransition>
        </TransitionGroup>
    )
}