import React from 'react';
import './style.scss';

export default () =>
	<svg className="logotype" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 317">
		<path d="M139.9,132.9l-20.4-9.1c-32.1-14.3-43.3-21-43.3-40.5c0-17.4,13.2-28.3,34.4-28.3c20.8,0,35.2,8.2,45.6,25.7l3.2,5.4
			l45.2-29.6l-3-4.9c-20.1-33.7-49.3-49.4-91.9-49.4c-53.3,0-90.6,34-90.6,82.8c0,48.5,30.7,72,76.9,92.3l20.4,9.1
			c27.2,12.3,43.8,21.4,43.8,44.4c0,9.8-4,17.7-11.9,23.4c-8.2,6-20,9.2-34.2,9.2c-27.6,0-48.9-12.7-61.5-36.7l-2.7-5.2L0,246.4
			l2.9,5.4C25.1,293.9,64.8,317,114.5,317c63.9,0,103.6-33.6,103.6-87.6C218.1,172.5,179.5,150.5,139.9,132.9z"/>
		<path d="M576.5,0L460.7,269.8l-92.6-130.7l109.8-133h-67.7L302.7,138V6.1h-58.4v307.1h58.4v-98l26.5-31.4l91,129.4H442l0,0h60
			l23-55.1h111.8l23,55.1H722L587,0H576.5z M614.9,205.2H547l34.3-81.6L614.9,205.2z"/>
		<path d="M952.3,105.8c0-61.5-43.9-99.7-114.5-99.7H737.2v307.1h58.4v-109H840l66,108.9h64.8l-73-120.2
			C933,177.4,952.3,146.8,952.3,105.8z M795.6,59.3H836c39.3,0,56,13.9,56,46.5s-16.8,46.5-56,46.5h-40.4V59.3z"/>
		<path d="M1085.5,6.1h-99.7v307.1h58.4V209h41.3c70.6,0,114.5-38.9,114.5-101.5C1200,45,1156.1,6.1,1085.5,6.1z M1044.2,59.3h39.6
			c39.3,0,56,14.4,56,48.2s-16.8,48.2-56,48.2h-39.6V59.3z"/>
	</svg>