const contactAddress = {
	name: "SKARP Technologies, Inc.",
	office: "Suite 1-2081",
	street: "100 24th Street West",
	county: "Billings",
	state: "MT",
	zip: "59134",
	country: "USA"
};

export default contactAddress;