import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Navigation from '../navigation';
import Logotype from '../logotype';
import './style.scss';

export default () => {
	const [activeMenu, setActiveMenu] = useState(false);
	const lockScroll = activeMenu ? "lock-scroll" : "";

	return (
		<>
			<Helmet
				bodyAttributes={{
					class: lockScroll
				}}
			/>
			<header id="main-header">
				{/* Desktop navigation */}
				<Container as="section" className="d-none d-lg-block desktop">
					<Row as="section">
						<Col as="section" lg={3}>
							<Link to="/">
								<Logotype />
							</Link>
						</Col>
						<Col as="section" lg={1}></Col>
						<Col as="section" lg={8} className="navigation-col">
							<Navigation isHeader={true} />
						</Col>
					</Row>
				</Container>

				{/* Mobile navigation */}
				<Container as="section" className="d-lg-none d-md-block mobile">
					<section
						role="menu"
						tabIndex={0}
						className={`menu ${activeMenu ? "show" : "hide"}`}
						onClick={() => setActiveMenu(!activeMenu)}
						onKeyDown={() => setActiveMenu(!activeMenu)}
					>
						<Navigation isHeader={true} />
					</section>
					<section>
						<Link to="/">
							<Logotype />
						</Link>
						<button
							className={`hamburger hamburger--collapse ${activeMenu ? "is-active" : null}`}
							type="button"
							aria-label="Menu"
							aria-controls="navigation"
							onClick={() => setActiveMenu(!activeMenu)}
						>
							<span className="hamburger-box">
								<span className="hamburger-inner"></span>
							</span>
						</button>
					</section>
				</Container>
			</header>
		</>
	);
}