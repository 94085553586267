import React from 'react';
import './style.scss';

export default ({ location }) => {
    let pageTitle;
    // Define page title
    switch (location) {
        case "/about/advisors/":
            pageTitle = "Management Team and Advisors"
            break;
        case "/about/board/":
            pageTitle = "Board of Directors"
            break;
        case "/about/":
            pageTitle = "About Us"
            break;
        case "/technology/":
            pageTitle = "Technology"
            break;
        case "/contact/":
            pageTitle = "Contact Us"
            break;
        case "/news/":
            pageTitle = "News"
            break;
        default:
            pageTitle = "";
            break;
    }

    return (
        <section id="page-title">
            <section className="container">
                <h1>{pageTitle}</h1>
            </section>
        </section>
    )
}