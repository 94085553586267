import React, { useState, useEffect } from 'react';
//import CookieConsent from 'react-cookie-consent';
import Transition from "./transition";
import Header from './header';
import PageTitle from './pageTitle';
import Sidebar from './sidebar';
import Footer from './footer';
import ConditionalWrapper from './conditionalWrapper';
import Store from '../store';

export default ({ children, location }) => {
	const [footerHeight, setFooterHeight] = useState(0);

	const pathname = location.pathname;
	const indexPage = pathname === "/" ? true : false;
	//const lvlOneTwo = RegExp('^/.*/.*.*?//$', 'gi').test(pathname);
	const aboutPath = /\/about\/(\w+\/){0,1}$/g.test(pathname);
	const locationDepth = !indexPage && pathname.split(/[?#]/).shift().match(/\/[^/]+?/g).length;
	const locationLvl = !indexPage && (locationDepth < 2 || aboutPath) ? true : false;

	const [state, setState] = useState({});
	useEffect(() => {
		setState(state => ({
			...state,
			location: pathname,
			setFooterHeight: setFooterHeight,
		}))
	}, [pathname, setFooterHeight]);

	return (
		<section id="main-wrapper" style={{ paddingBottom: footerHeight }}>
			<Store.Provider value={state}>
				<Header />
				<Transition location={pathname}>
					{!indexPage && locationLvl &&
						<PageTitle location={pathname} />
					}
				</Transition>
				<Transition location={pathname}>
					<main id="main-content-container">
						<ConditionalWrapper
							condition={!indexPage}
							wrapper={
								children =>
									<section className="container">
										<section className="row">
											<article className="col-md-12 col-lg-7">
												{children}
											</article>
											<section className="col-md-12 col-lg-1"></section>
											<aside className="col-md-12 col-lg-4">
												<Sidebar />
											</aside>
										</section>
									</section>
							}
						>
							{children}
						</ConditionalWrapper>
					</main>
				</Transition>
				<Footer />
			</Store.Provider>
		</section>
	);
}

/*
SAVE FOR LATER MAYBE
<CookieConsent
	//debug={true}
	location="bottom"
	buttonText="accept"
	cookieName="skarp-gdpr-analytics"
	style={{
		background:"#192643",
		margin:"0.5%",
		width:"99%",
		padding:"0.5% 1%",
		fontSize:"0.65rem"
	}}
	contentStyle={{
		margin:0,
		padding:0
	}}
	buttonStyle={{ background:"#D6BA76",color:"#192643",fontSize:"0.75rem",borderRadius:".15rem",margin:0 }}
	/*
	acceptOnScroll={true}
	acceptOnScrollPercentage={15}
	onAccept={({ acceptedByScrolling }) => {
		if(acceptedByScrolling) {
			// triggered if user scrolls past threshold
			const implicitConfirmMessage = intl.formatMessage({ id: "cookieConsent.implicitAcceptance" });
			alert(implicitConfirmMessage);
		}
	}}
	*
>
	By continuing you consent to cookies. <a href="#">Learn more</a>
</CookieConsent>
*/