const navigation = [
	{
		name: "Home",
		target: "/",
		id: 1
	},
	{
		name: "About us",
		target: "about",
		id: 2,
	},
	{
		name: "Technology",
		target: "technology",
		id: 3,
	},
	{
		name: "Contact us",
		target: "contact",
		id: 5,
	}
]

export default navigation;