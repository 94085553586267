import React, { useEffect, useRef, useContext } from 'react';
import Navigation from '../navigation';
import Store from '../../store';
import './style.scss';

export default () => {
    const footerRef = useRef(0);
    const { setFooterHeight } = useContext(Store);

    useEffect(() => {
        const footerHeight = footerRef.current.clientHeight;
        setFooterHeight && setFooterHeight(footerHeight);
    }, [setFooterHeight])

    return (
        <footer id="main-footer" ref={footerRef}>
            <section className="container text-muted text-small">
                <Navigation isHeader={false} />
                <p>
                    COPYRIGHT {new Date().getFullYear()} © SKARP TECHNOLOGIES, ALL RIGHTS RESERVED.
                </p>
            </section>
        </footer>
    );
}