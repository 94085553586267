import React, { useContext } from 'react';
import { pageCheck } from '../../global-helpers';
import { Link } from 'gatsby';
import navItems from '../navigation/navItems';
import Store from '../../store';
import contactAddress from '../contactAddress';
import './style.scss';

export default () => {
	const { location } = useContext(Store);

	return (
		<section id="sidebar">
			<section className="mb-5">
				<h4>About us</h4>
				<ul className="sidebar-navigation">
					{
						navItems[1].subLevel?.map((item, i) => {
							const target = item.target !== "top-lvl" ? `/about/${item.target}` : "/about";
							return (
								<li key={i} className="text-medium">
									<Link to={`${target}/`} activeClassName="active">{item.name}</Link>
								</li>
							)
						})
					}
				</ul>
			</section>

			{ !pageCheck("contact", location) &&
				<article className="text-medium">
					<h4>
						Contact us
					</h4>
					<p>
						<strong>
							{contactAddress.name}
						</strong>
					</p>
					<p>
						{contactAddress.street}<br />
						{contactAddress.office}<br />
						{contactAddress.county}, {contactAddress.state} {contactAddress.zip}<br />
						{contactAddress.country}
					</p>
				</article>
			}
		</section>
	)
}
/*
<p>
	<a href={`mailto: ${contactEmail}`}>{contactEmail}</a>
</p>
*/