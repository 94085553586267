import React, { useState } from 'react';
import { Link } from 'gatsby';
import navItems from './navItems';
import './style.scss';

export default ({ isHeader }) => {
	const [isOpen, setIsOpen] = useState(false);
	const onMouseEnter = () => {
		setIsOpen(true);
	}
	const onMouseLeave = () => {
		setIsOpen(false);
	}
	const onClick = () => {
		setIsOpen(false);
	}

	return (

		<ul className="main-navigation">
			{
				navItems.map((item, i) => {
					const index = `menu-item-${i}`;
					const target = item.target !== "/" ? `/${item.target}/` : item.target;
					const name = item.name;
					const subLevel = item.subLevel;
					const subItems = typeof subLevel !== "undefined";
					const hasSub = subItems && subLevel.length > 0;
					const subItemsClass = isHeader && hasSub ? "item-sub-items" : "";
					const hasSubClass = subItems ? "has-sub" : "";

					return (
						<li key={index}>
							<Link
								to={target}
								className={`${subItemsClass} ${hasSubClass}`}
								activeClassName="active"
								partiallyActive={subItems}
								onMouseEnter={hasSub ? onMouseEnter : null}
								onMouseLeave={hasSub ? onMouseLeave : null}
								onClick={onClick}
							>
								{name}
							</Link>
							{
								hasSub && isHeader ?
									<ul
										className={`sub-menu ${isOpen ? "show" : "hide"}`}
										onMouseEnter={onMouseEnter}
										onMouseLeave={onMouseLeave}
										onKeyDown={() => { }}
									>
										{
											item.subLevel.map((subItem) => {
												const subIndex = `menu-subitem-${subItem.id}`;
												const subTarget = subItem.target !== "top-lvl" ? subItem.target + "/" : "";

												return (
													<li key={subIndex}>
														<Link
															to={`${target}${subTarget}`}
															activeClassName="active"
															onClick={onClick}
														>
															{subItem.name}
														</Link>
													</li>
												)
											})
										}
									</ul>
									:
									null
							}
						</li>
					)
				})
			}
		</ul>
	);
}