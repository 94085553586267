import { createContext } from 'react';
export default createContext(() => { })


// import React, { createContext, useReducer } from "react";

// const Reducer = (state) => ({ ...state });
// export const Context = createContext({});

// export default ({ children }) => {
// 	const [state, dispatch] = useReducer(Reducer, {});
// 	return (
// 		<Context.Provider value={[state, dispatch]}>
// 			{children}
// 		</Context.Provider>
// 	)
// };

















// import { createContext } from 'react';
// export default createContext(() => { })


// import React, { createContext, useReducer } from 'react';

// const globalStateContext = createContext({});
// const dispatchStateContext = createContext(undefined);

// export default ({ children }) => {
// 	const [state, dispatch] = useReducer(
// 		(state, newValue) => ({ ...state, ...newValue }),
// 		globalStateContext
// 	);
// 	return (
// 		<globalStateContext.Provider value={state}>
// 			<dispatchStateContext.Provider value={dispatch}>
// 				{children}
// 			</dispatchStateContext.Provider>
// 		</globalStateContext.Provider>
// 	);
// };